import React from "react";
import { Link } from "react-router-dom";
const WelcomeCristo = () => {
  return (
    <>
      <h3 className="mt-4 text-center" id="welcomecristo">
        Stay connected with your parish family <br /> The smartest way to
        evangelize in today’s catholic church
      </h3>

      <section className="Blurbs--dark Blurbs--card Blurbs">
        <div className="container">
          <div className="Blurbs__blurb-wrapper spacebetween">
            <div className="Blurbs__blurb Blurbs__blurb--card Blurbs__blurb--1">
              <div className="Blurbs__blurb-inner">
                <div className="Blurbs__blurb-primary">
                  <div className="Blurbs__title">
                    <figure className="Blurbs__image">
                      <img
                        decoding="async"
                        src="https://elexio.wpenginepowered.com/wp-content/uploads/2019/07/church_management_software.png"
                        alt=""
                      />
                    </figure>

                    <h4 className="Blurbs__blurb-heading">
                      Simplify church management
                    </h4>
                  </div>
                  <div className="Blurbs__blurb-content">
                    <p>
                      Parokia Parish Office empowers parishes to simplify the tasks associated with the daily management of family and parish information. It helps you manage the myriad details required to ensure your parish runs efficiently, allowing you to focus on effectively addressing the needs of your parish community.
                    </p>
                  </div>
                </div>
                <div className="Blurbs__blurb-secondary">
                  <ul>
                    <li>
                      <i className="fas fa-check-circle fs-xl mt-1 me-3"></i>
                      <Link to="/dashboard">Dashboard</Link>
                    </li>
                    <li>
                      <i className="fas fa-check-circle fs-xl mt-1 me-3"></i>
                      <Link to="/families">Families</Link>
                    </li>
                    <li>
                      <i className="fas fa-check-circle fs-xl mt-1 me-3"></i>
                      <Link to="/members">Members</Link>
                    </li>
                    <li>
                      <i className="fas fa-check-circle fs-xl mt-1 me-3"></i>
                      <Link to="/baptism">Baptism</Link>
                    </li>
                    <li>
                      <i className="fas fa-check-circle fs-xl mt-1 me-3"></i>
                      <Link to="/holy-communion">Holy communion</Link>
                    </li>
                    <li>
                      <i className="fas fa-check-circle fs-xl mt-1 me-3"></i>
                      <Link to="/parish-prayer">Prayer request</Link>
                    </li>
                    <li>
                      <i className="fas fa-check-circle fs-xl mt-1 me-3"></i>
                      <Link to="/parish-liturgicalcalendar">
                        Liturgical calendar
                      </Link>
                    </li>
                    <li>
                      <i className="fas fa-check-circle fs-xl mt-1 me-3"></i>
                      <Link to="/parish-subscription">Subscription</Link>
                    </li>
                    <li>
                      <i className="fas fa-check-circle fs-xl mt-1 me-3"></i>
                      <Link to="/parish-scheduledsms">Scheduled SMS</Link>
                    </li>
                    <li>
                      <i className="fas fa-check-circle fs-xl mt-1 me-3"></i>
                      <Link to="/parish-donations">Donations</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="Blurbs__blurb Blurbs__blurb--card Blurbs__blurb--2">
              <div className="Blurbs__blurb-inner">
                <span
                  className="Blurbs__title"
                  href="/parish/parish-dashboard"
                  target>
                  <figure className="Blurbs__image">
                    <img
                      decoding="async"
                      src="/assets/img/module/dashboard.png"
                      alt=""
                    />
                  </figure>
                  <h4 className="Blurbs__blurb-heading">Dashboard</h4>
                </span>
                <div className="Blurbs__blurb-content">
                  <p>
                    <span>
                      A parish priest can view information about his own parish, including the total number of families, the total number of BCCs, and the total number of members.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="Blurbs__blurb Blurbs__blurb--card Blurbs__blurb--2">
              <div className="Blurbs__blurb-inner">
                <span
                  className="Blurbs__title"
                  href="/parish/sacrament/"
                  target>
                  <figure className="Blurbs__image">
                    <img
                      decoding="async"
                      src="/assets/img/module/Baptism.png"
                      alt=""
                    />
                  </figure>
                  <h4 className="Blurbs__blurb-heading">Sacraments</h4>
                </span>
                <div className="Blurbs__blurb-content">
                  <p>
                    <span>
                      The parokia sacramental register saves time by allowing you to search through various criteria to quickly locate the exact volume and page number of an entry in your physical register.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="Blurbs__blurb Blurbs__blurb--card Blurbs__blurb--2">
              <div className="Blurbs__blurb-inner">
                <span className="Blurbs__title">
                  <figure className="Blurbs__image">
                    <img
                      decoding="async"
                      src="/assets/img/module/Subscription.png"
                      alt=""
                    />
                  </figure>
                  <h4 className="Blurbs__blurb-heading">Subscription</h4>
                </span>
                <div className="Blurbs__blurb-content">
                  <p>
                    <span>
                      The subscription feature simplifies parish administration by allowing easy management of subscription payments made regularly by parishioners.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="Blurbs__blurb Blurbs__blurb--card Blurbs__blurb--2">
              <div className="Blurbs__blurb-inner">
                <span className="Blurbs__title">
                  <figure className="Blurbs__image">
                    <img
                      decoding="async"
                      src="/assets/img/module/Liturgical.png"
                      alt=""
                    />
                  </figure>
                  <h4 className="Blurbs__blurb-heading">Liturgical calendar</h4>
                </span>
                <div className="Blurbs__blurb-content">
                  <p>
                    <span>
                      The Liturgical Calendar feature allows parish administration to allocate events to the communities for organization, such as Daily Mass, Sunday Mass, and more.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WelcomeCristo;
