import React from "react";
import "./css/Security.css";

const Security = () => {
  return (
    <>
      <br />
      <br />
      <br />
      <br />
      <h1 className="text-center" id="mobhead" style={{ color: "#002856" }}>
        <b>Data security</b>
      </h1>
      <br />
      <br />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <center>
              <img
                className="banner-image text"
                src="https://img.freepik.com/premium-vector/cyber-security-illustration-concept-with-people_23-2148530634.jpg"
                alt="Cyber Security Banner"
              />
            </center>
            <div id="overview" className="legal-content-section legal-overview">
              <div className="unique-title">
                <h3 className=" terms-subheading mt-0 heading--h5" id="headtitle">
                  The utilization, retention, or retrieval of client information
                </h3>
              </div>
              <br />
              <ul>
                <li>
                  <i className="fas fa-check-circle text-success fs-xl mt-1 me-3" />
                  Within the bounds of this agreement and the contract, and only as
                  allowed
                </li>
                <li>
                  <i className="fas fa-check-circle text-success fs-xl mt-1 me-3" />
                  In strict adherence to all relevant laws and regulations that are
                  applicable to the service provider
                </li>
                <li>
                  <i className="fas fa-check-circle text-success fs-xl mt-1 me-3" />
                  Unless granted prior written authorization by the client, the
                  transfer, conveyance, or retention of client data beyond the
                  primary server to local desktop or laptop computers in the parish
                  office is strictly prohibited
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div id="overview" className="legal-content-section legal-overview">
              <div className="unique-title">
                <h3 className="terms-subheading mt-0 heading--h5" id="headtitle">
                  Safeguarding client data
                </h3>
              </div>
              <br />
              <p>
                The service provider acknowledges and commits to handling client
                data with the utmost care, adhering to industry-standard best
                practices. This involves maintaining the confidentiality,
                availability, and integrity of client data, as well as compliance
                with applicable state and federal laws and regulations. To achieve
                this, the following measures will be put in place.
              </p>
              <div className="container mt-4">
                <ul className="list-group">
                  <li>
                    <i className="fas fa-info-circle text-success fs-xl mt-1 me-2" />
                    Service provider will establish controls to proactively prevent
                    data breaches
                  </li>
                  <li>
                    <i className="fas fa-info-circle text-success fs-xl mt-1 me-2" />
                    The system will utilize secure protocols and encryption to
                    protect client Data during transit
                  </li>
                  <li>
                    <i className="fas fa-info-circle text-success fs-xl mt-1 me-2" />
                    Service provider will:
                    <br />
                    <br />
                    <ul>
                      <li>
                        <i className="fas fa-check-circle text-success fs-xl mt-1 me-3" />
                        Restrict administrative access to the system
                      </li>
                      <li>
                        <i className="fas fa-check-circle text-success fs-xl mt-1 me-3" />
                        Control remote access to the system
                      </li>
                      <li>
                        {" "}
                        <i className="fas fa-check-circle text-success fs-xl mt-1 me-3" />
                        Limit account access and privileges to the minimum necessary
                        for the system's proper operation
                      </li>
                      <li>
                        {" "}
                        <i className="fas fa-check-circle text-success fs-xl mt-1 me-3" />
                        Employ named user accounts rather than generic or shared
                        ones
                      </li>
                      <li>
                        {" "}
                        <i className="fas fa-check-circle text-success fs-xl mt-1 me-3" />
                        Implement appropriate auditing and logging for the operating
                        system and applications
                      </li>
                    </ul>
                  </li>
                  <li>
                    <i className="fas fa-info-circle text-success fs-xl mt-1 me-2" />{" "}
                    The system will allow for the changing of both system and user
                    passwords
                  </li>
                </ul>
                <br />
                <h4>
                  <i className="fas fa-check-circle text-secoundry fs-xl mt-1 me-3" />
                  Product maintenance and support
                </h4>
                <ul>
                  <li>
                    <i className="fa fa-info-circle text-success fs-xl mt-1 me-2" />{" "}
                    Service provider will have a process in place for timely review,
                    testing, and installation of critical patches to maintain the
                    confidentiality, integrity, and availability of the system and
                    client data
                  </li>
                  <li>
                    {" "}
                    <i className="fa fa-info-circle text-success fs-xl mt-1 me-2" />{" "}
                    Strict change management procedures will be followed
                  </li>
                  <li>
                    <i className="fa fa-info-circle text-success fs-xl mt-1 me-2" />{" "}
                    The product will receive support from service provider, provided
                    client maintains the required subscriptions. Service provider
                    will notify client 12 months in advance if the product is to
                    become unsupported
                  </li>
                  <li>
                    <i className="fa fa-info-circle text-success fs-xl mt-1 me-2" />
                    If necessary, and with the requisite subscriptions maintained by
                    the client, service provider will offer remote support through a
                    secure connection method that includes event auditing. Remote
                    access will be granted on an as-needed or as-requested basis
                  </li>
                </ul>
                <h4>
                  <i className="fas fa-check-circle text-secoundry fs-xl mt-1 me-3" />{" "}
                  Data protections
                </h4>
                <ul>
                  <li>
                    <i className="fa fa-info-circle text-success mt-1 me-2" />
                    Service provider will only utilize, store, disclose, or access
                    client data as required for agreed-upon purposes
                  </li>
                  <li>
                    <i className="fa fa-info-circle text-success mt-1 me-2" />
                    Adequate controls will be implemented to prevent unauthorized
                    use, disclosure, loss, acquisition, or access to client data.
                    This may involve personnel security measures, such as background
                    checks
                  </li>
                  <li>
                    <i className="fa fa-info-circle text-success mt-1 me-2" />
                    All transfers of client data by service provider will be
                    conducted using secure transfer methods
                  </li>
                </ul>
                <h4>
                  <i className="fas fa-check-circle text-secoundry fs-xl mt-1 me-3" />{" "}
                  Service Provider Access to Client Systems
                </h4>
                <ul>
                  <li>
                    <i className="fa fa-info-circle text-success mt-1 me-2" />
                    Client login credentials may be provided to service provider for
                    access to secured on-site computer equipment solely for
                    scheduled troubleshooting, maintenance, or updates related to
                    software provided by service provider and installed on
                    client-owned computer equipment. In such cases, client will
                    furnish service provider with the necessary credentials for
                    local login or through a secure Virtual private network (VPN),
                    if required
                  </li>
                  <li>
                    <i className="fa fa-info-circle text-success mt-1 me-2" />
                    All activities conducted by service provider while connected to
                    client computing equipment will be subject to monitoring by
                    client staff and verification by the respective client
                    department or division that requested the access
                  </li>
                  <li>
                    <i className="fa fa-info-circle text-success mt-1 me-2" />
                    Service provider, as a condition of accessing client computing
                    equipment, assures that it will only access the essential
                    system(s) and will not engage in any unlawful activities using
                    the provided equipment
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div id="overview" className="legal-content-section legal-overview">
              <div className="unique-title">
                <h3 className="terms-subheading mt-0 heading--h5" id="headtitle">
                  Data breach notification
                </h3>
              </div>
              <br />
              <p>
                In the event that the service provider becomes aware of any
                unauthorized access, disclosure, or acquisition of client data that
                contravenes the terms of this agreement or the contract, the service
                provider will make every reasonable effort to promptly inform the
                client of such a data breach within one business day. Additionally,
                the service provider will immediately take appropriate actions to
                safeguard forensic evidence and rectify the root cause of the data
                breach. Rectifying the data breach will be the service provider's
                utmost priority, and the necessary resources will be allocated to
                achieve this objective. The service provider will furnish the client
                with all essential information to facilitate a comprehensive
                understanding of the nature and extent of the data breach.
              </p>
              <br />
              <p>
                If applicable laws mandate it, the service provider will notify
                affected parties of the data breach and provide credit monitoring
                services. Upon request, the service provider will also disclose the
                actions taken or planned to mitigate the adverse effects of
                unauthorized access, disclosure, or use of client data. If the data
                breach necessitates assistance from the service provider in
                reinstalling software, such assistance will be provided to the
                Client at no cost. The client retains the discretion to discontinue
                any services or products provided by the service provider until it
                is satisfied that the cause of the data breach has been adequately
                addressed.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div id="overview" className="legal-content-section legal-overview">
              <div className="unique-title">
                <h3 className="terms-subheading mt-0 heading--h5" id="headtitle">
                  No surreptitious code
                </h3>
              </div>
              <br />
              <p>
                Service provider warrants that, to the best of its knowledge, the
                system is free of and does not contain any code or mechanism that
                collects personal information or asserts control of the system
                without client’s consent, or which may restrict client’s access to
                or use of client data. Service provider further warrants that it
                will not knowingly introduce, via any means, spyware, adware,
                ransomware, rootkit, keylogger, virus, trojan, worm, or other code
                or mechanism designed to permit unauthorized access to client data,
                or which may restrict client’s access to or use of client data.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div id="overview" className="legal-content-section legal-overview">
              <div className="unique-title">
                <h3 className="terms-subheading mt-0 heading--h5" id="headtitle">
                  Compelled disclosure
                </h3>
              </div>
              <br />
              <p>
                If service provider is served with any subpoena, discovery request,
                court order, or other legal request or command that calls for
                disclosure of any client data, service provider shall promptly
                notify the client in writing and provide the client sufficient time
                to obtain a court order or take any other action the client deems
                necessary to prevent disclosure or otherwise protect client data. In
                such event, service provider shall provide client prompt and full
                assistance in client’s efforts to protect client data. Where service
                provider is prohibited by law from notifying the client of a legal
                request for client data, Service provider will comply with all
                applicable laws and regulations with respect to the requested client
                data.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="unique-title">
        <h3 className="terms-subheading mt-0 heading--h5" id="headtitle">
          Termination process
        </h3>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div id="overview" className="legal-content-section legal-overview">
              <p>
                Upon the expiration or termination of the contract, the service
                provider will ensure that no data breach occurs and will comply with
                the client's instructions regarding the preservation, transfer, or
                destruction of client data. Industry-standard methods of
                destruction, such as "purging" or "physical destruction," will be
                employed. Upon request, the service provider will furnish the client
                with written certification of the completion of data return or
                destruction. Prior to such return or destruction, client data will
                continue to be protected in accordance with the terms of this
                Agreement.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div id="overview" className="legal-content-section legal-overview">
              <div className="unique-title">
                <h3 className="terms-subheading mt-0 heading--h5" id="headtitle">
                  Survival and precedence
                </h3>
              </div>
              <br />
              <p>
                This agreement will remain in effect even after the expiration or
                earlier termination of the contract. however, either party may
                terminate this agreement upon the expiration or termination of the
                contract. In the event of any conflict between the provisions of
                this agreement and those of the contract, service provider's
                warranties, support contract, or service level agreement, the
                provisions of this agreement shall take precedence.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div id="overview" className="legal-content-section legal-overview">
              <div className="unique-title">
                <h3 className="terms-subheading mt-0 heading--h5" id="headtitle">
                  Definitions
                </h3>
              </div>
              <b>
                <i className="fas fa-check-circle text-success fs-xl mt-1 me-3" />{" "}
                Client Data
              </b>
              <p>
                {" "}
                Refers to all data disclosed by the client to the service provider.
                For the purposes of this agreement, client data remains as such even
                if it is transmitted or transferred beyond the immediate possession,
                custody, or control of the client
              </p>
              <b>
                <i className="fas fa-check-circle text-success fs-xl mt-1 me-3" />
                Data breach
              </b>
              <p>
                Denotes the unauthorized access and acquisition of computerized data
                that significantly compromises the security or confidentiality of
                confidential or sensitive personal information stored by the client
                as part of a database containing personal information about multiple
                individuals. Such a breach is believed to have caused or is
                reasonably expected to cause loss or harm to any constituent of the
                client
              </p>
              <b>
                <i className="fas fa-check-circle text-success fs-xl mt-1 me-3" />
                System
              </b>
              <p>
                {" "}
                Comprises a collection of components that support an operational
                function or achieve a specific objective. This may encompass various
                information resources such as networks, servers, computers,
                software, applications, operating systems, or storage devices,
                organized for the purpose of collecting, processing, maintaining,
                using, sharing, disseminating, or disposing of information
              </p>
              <b>
                <i className="fas fa-check-circle text-success fs-xl mt-1 me-3" />
                Change management
              </b>
              <p>
                A formal process employed to ensure controlled and coordinated
                introduction of changes to a system, reducing the likelihood of
                unnecessary modifications, introduction of faults or
                vulnerabilities, or undoing changes made by other users
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Security;
