import React from "react";

const Slider = () => {
  const slides = [
    {
      backgroundImage: "url(assets/img/cristo/04-slide.jpg)",
      title:
        "<span>Welcome to</span> <br/> Parish Management Software <span style='color:#ff8001'>( Parokia )</span>",
      content:
        "Store & organize the member data that’s best for your church with Parokia. Effective parish management software is a comprehensive digital solution designed to streamline and enhance the administrative and organizational tasks of a religious congregation. Parokia helps manage families, members, and sacraments, making it easier for parishes with facilities to stay organized. Parokia is accessible at the diocesan, parish, family, or individual member level. A diocesan administrator can have access to all information related to the diocese."
    },
  ];

  return (
    <section id="hero">
      <div
        id="heroCarousel"
        data-bs-interval={8000}
        className="carousel slide carousel-fade"
        data-bs-ride="carousel">
        <div className="carousel-inner" role="listbox">
          {slides.map((slide, index) => (
            <div
              key={index}
              className="carousel-item active"
              style={{ backgroundImage: slide.backgroundImage }}>
              <div className="overlay"></div>
              <div className="carousel-container">
                <div className="container">
                  <h2
                    className="animate__animated animate__fadeInDown"
                    dangerouslySetInnerHTML={{ __html: slide.title }}></h2>
                  <p
                    className="animate__animated animate__fadeInUp"
                    dangerouslySetInnerHTML={{ __html: slide.content }}></p>
                  <button
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                    className="btn btn-primary getstarted">
                    Request For Demo
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Slider;
